#property-details {
    [class*="icon-proe"] {
        margin-right: 0.2em;
    }
    .prop-header {
        background: $main-color-alpha;
        color: #fff;
        padding: 5px 0;
        h1 {
            font-size: 16px;
        }
        .navigator {
            text-align: center;
            margin: 0 0 5px;
            .prev {
                text-align: left;
                button {
                    display: flex;
                    align-items: center;
                    padding-right: 20px;
                    border-right: 1px solid #ccc;
                    i {
                        margin-right: 4px;
                        display: flex;
                        align-items: center;
                    }
                }
            }
            .next {
                text-align: right;
                button {
                    display: flex;
                    align-items: center;
                    padding-left: 20px;
                    border-left: 1px solid #ccc;
                    i {
                        margin-left: 4px;
                        display: flex;
                        align-items: center;
                    }
                }
            }
            button {
                color: #fff;
                display: inline-block;
                cursor: pointer;
                background: none;
                border: none;
                border-radius: 0;
                padding: 0;
                font-size: 14px;
                &[disabled] {
                    color: #aaa;
                }
            }
            .ref {
                text-align: right;
                padding-bottom: 10px;
                strong {
                    font-size: 22px;
                }
            }
        }
        .indetails {
            margin: 0;
            line-height: 18px;
            [class*="icon-proe"] {
                font-size: 16px;
                margin-right: 10px;
            }
            .price {
                font-size: 22px;
                font-weight: bold;
                margin-right: 5px;
            }
            .bedbath {
                margin-left: 20px;
            }
        }
    }
    .prop-body {
        margin: 20px 0 0;
        .photo-container {
            position: relative;
            margin: 0 -15px;
            .property-sizes-area {
                position: absolute;
                left: 0;
                bottom: 0;
                padding: 3px 10px;
                background: rgba(0, 0, 0, .5);
                color: #ccc;
            }
        }
        .carousel {
            height: 500px;
            overflow: hidden;
            .carousel-inner {
                height: 100%;
                min-height: 500px;
                background: #444;
            }
            .carousel-item {
                height: 100%;
                .image {
                    height: 100%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    object-fit: contain;
                }
            }
            .carousel-control-prev-icon,
            .carousel-control-next-icon {
                filter: drop-shadow(0 0 2px black);
            }
        }
        .builts {
            background: #fff;
            padding: 15px 0;
            .text {
                font-size: 16px;
            }
            .value {
                .number {
                    font-size: 24px;
                    font-weight: bold;
                    margin-right: 5px;
                    color: $main-color;
                }
                .unit {
                    font-weight: bold;
                    color: #999;
                }
            }
        }
        .prop-desc {
            border-top: 1px solid #ccc;
            padding: 20px 0;
            font-size: 14px;
            background: #fff;
            button {
                margin-left: 10px;
                font-weight: bold;
            }
        }
        .prop-rental-premises {
            font-size: 12px;
            text-align: center;
            padding: 0 15px 15px;
            border-bottom: 1px solid #ccc;
            background-color: #fff;
        }
        .preserved-break-line {
            white-space: pre-line;
        }
    }
    .prop-accor {
        background: #fff;
        border-bottom: 1px solid #ccc;
        padding: 15px 0;
    }
    .property-right-col {
        background: #fff;
        border-bottom: 1px solid #ccc;
        padding: 15px 0;
    }
    .text-center {
        text-align: center;
    }
    .area-agency {
        .agency-logo {
            margin-top: 10px;
            margin-bottom: 10px;
            img {
                max-width: 100%;
            }
        }
        .agency-info {
            padding-bottom: 20px;
            .list-group {
                .list-group-item {
                    border: 0;
                    text-align: left;
                    padding: 0px 10px;
                    a {
                        color: #222;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .area-location {
        margin-top: 10px;
    }
    .features {
        .category {
            font-weight: 600;
            font-size: 17px;
        }
        .items {
            font-size: 14px;
            list-style: none;
            margin: 10px 0 25px;
            padding: 0;
            .showmore {
                font-weight: bold;
                cursor: pointer;
            }
            .hidden {
                display: none;
            }
            .icon {
                color: $main-color;
            }
            &.showup {
                .hidden {
                    display: block;
                }
                .showmore {
                    display: none;
                }
            }
        }
    }
    .average-price-table {
        td,
        th {
            border: 1px solid #dee2e6;
        }
        th {
            text-transform: uppercase;
            font-size: 13px;
            font-weight: normal;
            color: #717171;
            padding: 4px 15px;
            background: #ededed;
        }
        .subtitle {
            text-transform: uppercase;
            font-size: 13px;
            font-weight: normal !important;
            background: #ededed;
            color: $sub-color;
        }
        td {
            padding: 7px 15px;
            &.divider {
                font-size: 18px;
                background: #fff !important;
                border: none;
                font-weight: bolder;
                .erShowfull {
                    float: right;
                    font-weight: bold;
                    .icon {
                        margin-left: 5px;
                    }
                }
            }
            &.emptyRow {
                background: none !important;
                border: none;
                padding-top: 5px;
                font-weight: bold;
                font-color: $main-color;
            }
        }
        td:first-child:not(.divider) {
            background: #ededed;
            font-weight: 600;
            width: 30%;
        }
        .letter-energy {
            display: inline-block;
            width: 32px;
            height: 32px;
            line-height: 32px;
            margin: -5px 5px -8px -12px;
            background: #ccc;
            text-align: center;
            font-weight: bold;
            font-size: 20px;
            &.letter-A {
                background-color: #0C954D;
            }
            &.letter-B {
                background-color: #21B24B;
            }
            &.letter-C {
                background-color: #99CA3B;
            }
            &.letter-D {
                background-color: #ECE824;
            }
            &.letter-E {
                background-color: #F0B418;
            }
            &.letter-F {
                background-color: #E17628;
            }
            &.letter-G {
                background-color: #E52E2A;
            }
        }
    }
    .accordion {
        .cardheader {
            padding: 10px 15px 5px;
            .icon {
                font-size: 24px;
            }
            .icon-toggle {
                font-size: 18px;
                float: right;
                margin: 5px 5px 0 0;
            }
        }
        .card {
            border: none;
            h2 {
                font-size: 22px;
                font-weight: bold;
                margin-left: -15px;
            }
            .raiiNo {
                cursor: context-menu;
            }
            .card-header-tabs {
                margin: 0 0 15px;
                border-bottom: 1px solid #ccc;
                .nav-item.nav-link {
                    border-top-width: 3px;
                    border-radius: 0;
                    &.active {
                        background: #fff;
                        color: $main-color;
                        border-bottom: none;
                        border-top-color: $main-color;
                    }
                }
            }
        }
    }
    .prop-tail {
        background: rgba(3, 3, 3, .85);
        margin-top: 20px;
        color: white;
        padding: 10px 10px;
        font-size: 11px;
    }
}

@media (min-width: 992px) {
    #property-details {
        .property-right-col {
            background: none;
            border-bottom: none;
            padding: 0;
            .accordion .cardheader h2 {
                margin-left: 0;
            }
        }
    }
}

@media (min-width: 768px) {
    #property-details {
        .prop-header.fixed-top.position-sticky {
            h1 {
                font-size: 22px;
            }
            .navigator {
                margin: 0 0 10px;
            }
            .indetails {
                line-height: 26px;
                [class*="icon-proe"] {
                    font-size: 20px;
                    margin-right: 10px;
                }
                .price {
                    font-size: 28px;
                    font-weight: bold;
                    margin-right: 5px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    #property-details {
        .average-price-table td:first-child:not(.divider) {
            width: 50%;
        }
        .property-right-col {}
        .accordion-cont {
            margin: -15px;
            margin: -15px;
            .card {
                background: none;
                .cardheader {
                    padding-left: 15px;
                    padding-right: 15px;
                    background: $main-bg;
                    h2 {
                        margin-left: 0;
                    }
                }
                .card-header-tabs {
                    margin: 15px 0;
                }
                .collapse,
                .collapsing {
                    margin: 0 15px;
                }
                .features {
                    margin-top: 15px;
                }
            }
        }
        .prop-body {
            margin-top: 0;
            .carousel {
                height: 340px;
                .carousel-inner {
                    min-height: 340px;
                }
                .carousel-item .image {
                    height: auto;
                    max-height: 340px;
                }
            }
        }
        .prop-tail {
            margin-top: 0;
        }
    }
    .properties-list .property .feature-image {
        .carousel {
            .carousel-inner {
                min-height: 240px;
            }
            .carousel-item .image {
                height: 240px;
            }
        }
    }
}