.home {
    h1 {
        font-size:20px;
        margin-top:40px;
    }

    .well {
        border-radius:10px;
        background:rgba(255,255,255,0.5);
        margin-top:50px;
        padding-top:15px;
        padding-bottom:15px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .verify-url {
        .verify-label {
            width: 100%;
            padding: 10px 0px;
        }
    }
}

.appLoading {
    position:absolute;top: calc(50% - 50px);left:calc(50% - 100px);
    padding: 30px 0 0;
    width: 200px;height: 100px;
    border-radius: 5px;
    background: rgba(255,255,255,0.5);
    text-align: center;
}
