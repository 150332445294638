// Override default variables before the import
$body-bg: #e8ecef;
$main-color:#333;
$main-color-alpha:rgba(51, 51, 51, .94);
$sub-color:#717171;
$main-bg:#ededed;
$link-color:#449;
$link-color-hover:#55a;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';