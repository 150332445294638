.image-not-found {
    text-align: center;
    font-size: 36px;
    .img-404 {
        margin-top: 60px;
        width:  300px;
    }
    .sorry {
        // color:#fff;
        margin-top: 50px;
        font-weight: bold;
        .bold-red {
          color: #ccc;
        }
    }
    .thank-you {
        font-weight: 500;
        margin-top: 30px;
    }
    .contact {
        margin-top: 30px;
        font-size: 24px;
    }
}
