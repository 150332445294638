body {
    font-family: "Titillium Web", "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height: 1.42857;
    color: #333333;
    background-repeat:no-repeat;
    background-size:cover;
    background-position:top center;
    background-attachment:fixed;
    background-color:#e8ecef;

    &.inside {
        background-image:none;
        background-color:$body-bg;
    }

    a {
        color:$link-color;
        cursor:pointer;
    }
}

@supports (-webkit-overflow-scrolling: touch) {
    body {
        background-attachment: scroll;
    }
}

.container-fluid {
    padding-right: 0px;
    padding-left: 0px;
}

.header {
    background-color:#fff;
    padding:10px 15px 0;
    height: 62px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    img {
        height: 40px;
        width: auto;
        cursor: pointer;
    }

    .raiiNo {
        font-weight: bold;
        font-size: 16px;
        color:#fff;
        display: inline-block;
        margin: 3px 0 0 10px;
    }
}

.inside {
    .header {
        background-color:rgba(3,3,3,.85) !important;
    }
}

button.asLink {
    color:$link-color;
    background:none;border:none;border-radius:0;
    padding:0;margin:0;
    font-size:inherit;line-height:1em;
    vertical-align:baseline;

    &:hover {
        color:$link-color-hover;
        background:none;
    }
}

.btn-primary:focus {
    color:$link-color-hover;
    background-color: unset;
    border-color: transparent;
    box-shadow: unset;
}
