$icomoon-font-path: "../fonts";
$propextra-font-path: "../fonts";
@font-face {
    font-family: 'icomoon';
    src: url('#{$icomoon-font-path}/icomoon.eot?5fihgt');
    src: url('#{$icomoon-font-path}/icomoon.eot?5fihgt#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/icomoon.ttf?5fihgt') format('truetype'),
    url('#{$icomoon-font-path}/icomoon.woff?5fihgt') format('woff'),
    url('#{$icomoon-font-path}/icomoon.svg?5fihgt#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "propextra1";
    src:url("#{$propextra-font-path}/propextra1.eot");
    src:url("#{$propextra-font-path}/propextra1.eot?#iefix") format("embedded-opentype"),
    url("#{$propextra-font-path}/propextra1.woff") format("woff"),
    url("#{$propextra-font-path}/propextra1.ttf") format("truetype"),
    url("#{$propextra-font-path}/propextra1.svg#propextra1") format("svg");
    font-weight: normal;
    font-style: normal;
}

.icon {
    vertical-align: middle;
}

[data-icon]:before {
    font-family: "propextra1" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-proe-"]:before,
[class*=" icon-proe-"]:before {
    font-family: "propextra1" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.property-beds > span, 
.property-baths > span {
  padding-left: 6px;
}

.font-icon{
  line-height: 100%;
}

.icon-proe-adv-search:before {
  content: "\61";
}
.icon-proe-agent:before {
  content: "\62";
}
.icon-proe-apartment:before {
  content: "\64";
}
.icon-proe-arrow-next:before {
  content: "\65";
}
.icon-proe-arrow-next-dotted:before {
  content: "\66";
}
.icon-proe-bathroom:before {
  content: "\67";
}
.icon-proe-bedroom:before {
  content: "\68";
}
.icon-proe-built:before {
  content: "\69";
}
.icon-proe-chart-2:before {
  content: "\6a";
}
.icon-proe-check:before {
  content: "\6b";
}
.icon-proe-check-list:before {
  content: "\6c";
}
.icon-proe-commericals:before {
  content: "\6d";
}
.icon-proe-data-export:before {
  content: "\6e";
}
.icon-proe-description:before {
  content: "\6f";
}
.icon-proe-drop:before {
  content: "\72";
}
.icon-proe-euro:before {
  content: "\73";
}
.icon-proe-pinterest:before {
  content: "\e075";
}
.icon-proe-fav:before {
  content: "\74";
}
.icon-proe-fav-add:before {
  content: "\75";
}
.icon-proe-fav-added:before {
  content: "\76";
}
.icon-proe-fb:before {
  content: "\77";
}
.icon-proe-garden:before {
  content: "\78";
}
.icon-proe-gg:before {
  content: "\79";
}
.icon-proe-id:before {
  content: "\41";
}
.icon-proe-largenext:before {
  content: "\42";
}
.icon-proe-largepre:before {
  content: "\43";
}
.icon-proe-location:before {
  content: "\44";
}
.icon-proe-long-rent:before {
  content: "\45";
}
.icon-proe-mail:before {
  content: "\46";
}
.icon-proe-network-user:before {
  content: "\48";
}
.icon-proe-next:before {
  content: "\49";
}
.icon-proe-photo:before {
  content: "\4a";
}
.icon-proe-plot:before {
  content: "\4b";
}
.icon-proe-plot-property:before {
  content: "\4c";
}
.icon-proe-plots:before {
  content: "\4d";
}
.icon-proe-prev:before {
  content: "\4e";
}
.icon-proe-terrace:before {
  content: "\4f";
}
.icon-proe-up:before {
  content: "\51";
}
.icon-proe-property-price:before {
  content: "\52";
}
.icon-proe-property-size:before {
  content: "\53";
}
.icon-proe-searchshape-28:before {
  content: "\54";
}
.icon-proe-top-to:before {
  content: "\55";
}
.icon-proe-short-rent:before {
  content: "\56";
}
.icon-proe-support:before {
  content: "\57";
}
.icon-proe-user:before {
  content: "\58";
}
.icon-proe-user-setting:before {
  content: "\59";
}
.icon-proe-villa:before {
  content: "\5a";
}
.icon-proe-edit:before {
  content: "\30";
}
.icon-proe-trash:before {
  content: "\31";
}
.icon-proe-forbid:before {
  content: "\32";
}
.icon-proe-grid:before {
  content: "\33";
}
.icon-proe-list:before {
  content: "\34";
}
.icon-proe-sent-mail:before {
  content: "\36";
}
.icon-proe-checked-list:before {
  content: "\37";
}
.icon-proe-twister:before {
  content: "\50";
}
.icon-proe-bars:before {
  content: "\38";
}
.icon-proe-enlarge:before {
  content: "\21";
}
.icon-proe-share:before {
  content: "\39";
}
.icon-proe-saved-property:before {
  content: "\23";
}
.icon-proe-question:before {
  content: "\35";
}
.icon-proe-lock:before {
  content: "\22";
}
.icon-proe-forbidden:before {
  content: "\24";
}
.icon-proe-close-round:before {
  content: "\25";
}
.icon-proe-calculator:before {
  content: "\26";
}
.icon-proe-angle-left:before {
  content: "\27";
}
.icon-proe-angle-right:before {
  content: "\28";
}
.icon-proe-angle-down:before {
  content: "\29";
}
.icon-proe-angle-up:before {
  content: "\2a";
}
.icon-proe-angle-double-up:before {
  content: "\2b";
}
.icon-proe-angle-double-right:before {
  content: "\2c";
}
.icon-proe-angle-double-left:before {
  content: "\2d";
}
.icon-proe-angle-double-down:before {
  content: "\2e";
}
.icon-proe-clone:before {
  content: "\2f";
}
.icon-proe-refresh:before {
  content: "\3a";
}
.icon-proe-close-empty:before {
  content: "\3b";
}
.icon-proe-log-out:before {
  content: "\3c";
}
.icon-proe-target:before {
  content: "\3d";
}
.icon-proe-arrows-alt:before {
  content: "\3e";
}
.icon-proe-arrows-h:before {
  content: "\3f";
}
.icon-proe-arrows:before {
  content: "\5b";
}
.icon-proe-caret-down:before {
  content: "\5d";
}
.icon-proe-caret-left:before {
  content: "\5e";
}
.icon-proe-caret-right:before {
  content: "\5f";
}
.icon-proe-caret-up:before {
  content: "\60";
}
.icon-proe-chevron-down:before {
  content: "\7b";
}
.icon-proe-chevron-up:before {
  content: "\7c";
}
.icon-proe-sliders:before {
  content: "\7d";
}
.icon-proe-files:before {
  content: "\7e";
}
.icon-proe-id-card:before {
  content: "\3e";
}
.icon-proe-credit-cards:before {
  content: "\3f";
}
.icon-proe-card-user-2:before {
  content: "\3d";
}
.icon-proe-credit-card:before {
  content: "\40";
}
.icon-proe-credit-card-1:before {
  content: "\5b";
}
.icon-proe-film:before {
  content: "\5d";
}
.icon-proe-file-text-o:before {
  content: "\5e";
}
.icon-proe-sort:before {
  content: "\5f";
}
.icon-proe-android-arrow-back:before {
  content: "\60";
}
.icon-proe-android-arrow-down:before {
  content: "\7b";
}
.icon-proe-android-arrow-up:before {
  content: "\7c";
}
.icon-proe-android-arrow-forward:before {
  content: "\7d";
}
.icon-proe-android-close:before {
  content: "\7e";
}
.icon-proe-android-open:before {
  content: "\5c";
}
.icon-proe-android-options:before {
  content: "\e000";
}
.icon-proe-ios-checkmark-outline:before {
  content: "\e001";
}
.icon-proe-ios-close-outline:before {
  content: "\e002";
}
.icon-proe-log-in:before {
  content: "\e004";
}
.icon-proe-more:before {
  content: "\e003";
}
.icon-proe-paperclip:before {
  content: "\e005";
}
.icon-proe-like:before {
  content: "\40";
}
.icon-proe-files:before {
  content: "\e006";
}
.icon-proe-target:before {
  content: "\e007";
}
.icon-proe-clear:before {
  content: "\e008";
}
.icon-proe-block:before {
  content: "\e009";
}
.icon-proe-dashboard:before {
  content: "\e00a";
}
.icon-proe-whatsapp:before {
  content: "\e00b";
}
.icon-proe-clipboard:before {
  content: "\e00c";
}
.icon-proe-clock:before {
  content: "\e00d";
}
.icon-proe-compose:before {
  content: "\e00e";
}
.icon-proe-book:before {
  content: "\e00f";
}
.icon-proe-file-text:before {
  content: "\e010";
}
.icon-proe-package:before {
  content: "\e011";
}
.icon-proe-flow-tree:before {
  content: "\e012";
}
.icon-proe-credit-card-2:before {
  content: "\e013";
}
.icon-proe-comment:before {
  content: "\e014";
}
.icon-proe-email:before {
  content: "\e015";
}
.icon-proe-file:before {
  content: "\e016";
}
.icon-proe-bubble-3:before {
  content: "\e017";
}
.icon-proe-diamond:before {
  content: "\e018";
}
.icon-proe-star:before {
  content: "\e019";
}
.icon-proe-ios-plus-outline:before {
  content: "\e01a";
}
.icon-proe-plus:before {
  content: "\e01c";
}
.icon-proe-android-remove:before {
  content: "\e01b";
}
.icon-proe-ios-minus-outline:before {
  content: "\e01d";
}
.icon-proe-phone:before {
  content: "\e01e";
}
.icon-proe-ios-printer-outline:before {
  content: "\e01f";
}
.icon-proe-ptint:before {
  content: "\e020";
}
.icon-proe-print:before {
  content: "\e021";
}
.icon-proe-android-alert:before {
  content: "\e022";
}
.icon-proe-alert-1:before {
  content: "\e023";
}
.icon-proe-ios-telephone:before {
  content: "\e024";
}
.icon-proe-tumblr:before {
  content: "\e026";
}
.icon-proe-linkedin:before {
  content: "\e027";
}
.icon-proe-instagram:before {
  content: "\e025";
}
.icon-proe-wikipedia:before {
  content: "\e028";
}
.icon-proe-map:before {
  content: "\e029";
}
.icon-proe-map-2:before {
  content: "\e02b";
}
.icon-proe-up-box:before {
  content: "\e02a";
}
.icon-proe-down-box:before {
  content: "\e02c";
}
.icon-proe-question-1:before {
  content: "\e02e";
}
.icon-proe-popup:before {
  content: "\e02f";
}
.icon-proe-themify:before {
  content: "\e030";
}
.icon-proe-themify-1:before {
  content: "\e031";
}
.icon-proe-themify-2:before {
  content: "\e032";
}
.icon-proe-themify-3:before {
  content: "\e033";
}
.icon-proe-help:before {
  content: "\e034";
}
.icon-proe-info-circled:before {
  content: "\e035";
}
.icon-proe-exclamation-triangle:before {
  content: "\e036";
}
.icon-proe-warning:before {
  content: "\e037";
}
.icon-proe-prohibited:before {
  content: "\e038";
}
.icon-proe-down-menu:before {
  content: "\e039";
}
.icon-proe-up-menu:before {
  content: "\e03a";
}
.icon-proe-down-medium:before {
  content: "\e03b";
}
.icon-proe-up-medium:before {
  content: "\e03c";
}
.icon-proe-add-location:before {
  content: "\e03e";
}
.icon-proe-alert:before {
  content: "\63";
}
.icon-proe-hous-for-sale:before {
  content: "\e03f";
}
.icon-proe-school:before {
  content: "\e040";
}
.icon-proe-shopping-mall:before {
  content: "\e041";
}
.icon-proe-airport:before {
  content: "\e042";
}
.icon-proe-bank:before {
  content: "\e043";
}
.icon-proe-park:before {
  content: "\e046";
}
.icon-proe-pharmacy:before {
  content: "\e047";
}
.icon-proe-train-station:before {
  content: "\e048";
}
.icon-proe-walking:before {
  content: "\e049";
}
.icon-proe-themify-4:before {
  content: "\e04a";
}
.icon-proe-themify-5:before {
  content: "\e04b";
}
.icon-proe-themify-6:before {
  content: "\e04c";
}
.icon-proe-themify-7:before {
  content: "\e04d";
}
.icon-proe-themify-8:before {
  content: "\e04e";
}
.icon-proe-themify-9:before {
  content: "\e04f";
}
.icon-proe-themify-10:before {
  content: "\e050";
}
.icon-proe-themify-11:before {
  content: "\e051";
}
.icon-proe-chart:before {
  content: "\e052";
}
.icon-proe-pulse:before {
  content: "\e053";
}
.icon-proe-themify-13:before {
  content: "\e054";
}
.icon-proe-themify-14:before {
  content: "\e056";
}
.icon-proe-themify-15:before {
  content: "\e055";
}
.icon-proe-themify-16:before {
  content: "\e057";
}
.icon-proe-near:before {
  content: "\e058";
}
.icon-proe-down:before {
  content: "\70";
}
.icon-proe-l:before {
  content: "\e05d";
}
.icon-proe-hospital-1:before {
  content: "\71";
}
.icon-proe-gym:before {
  content: "\e02d";
}
.icon-proe-investment:before {
  content: "\e03d";
}
.icon-proe-hand:before {
  content: "\e044";
}
.icon-proe-distressed:before {
  content: "\e045";
}
.icon-proe-bus:before {
  content: "\7a";
}
.icon-proe-themify-12:before {
  content: "\47";
}
.icon-proe-themify-17:before {
  content: "\e059";
}
.icon-proe-driving:before {
  content: "\e05a";
}
.icon-proe-no-furnished:before {
  content: "\e05b";
}
.icon-proe-swimmers:before {
  content: "\e05c";
}
.icon-proe-swimming-pool:before {
  content: "\e05e";
}
.icon-proe-furnished:before {
  content: "\e05f";
}
.icon-proe-parking:before {
  content: "\e060";
}
.icon-proe-p:before {
  content: "\e061";
}
.icon-proe-dot-3:before {
  content: "\e062";
}
.icon-proe-bell-o:before {
  content: "\e063";
}
.icon-proe-world:before {
  content: "\e064";
}
.icon-proe-property-list:before {
  content: "\e065";
}
.icon-proe-villa-tree:before {
  content: "\e066";
}
.icon-proe-first-property:before {
  content: "\e067";
}
.icon-proe-android-time:before {
  content: "\e068";
}
.icon-proe-arrow-back-to:before {
  content: "\e069";
}
.icon-proe-calendar-plus-o:before {
  content: "\e06a";
}
.icon-proe-calendar-check-o:before {
  content: "\e06b";
}
.icon-proe-check-clipboard-1:before {
  content: "\e06c";
}
.icon-proe-themify-18:before {
  content: "\e06d";
}
.icon-proe-sea:before {
  content: "\e06e";
}
.icon-proe-pdf-file:before {
  content: "\e06f";
}
.icon-proe-download:before {
  content: "\e070";
}
.icon-proe-download-1:before {
  content: "\e071";
}
.icon-proe-cloud-download-2:before {
  content: "\e072";
}
.icon-proe-in:before {
  content: "\e073";
}
.icon-proe-euro:before {
  content: "\e074";
}